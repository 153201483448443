.root {
  width: 100%;
  margin-bottom: 12px;
}

.root :global {
  .ant-form-item-label {
    padding: 0 0 12px;

    line-height: var(--text_default_line_height);
    color: var(--label_color_default);
    font-size: var(--text_default_font_size);
    font-weight: var(--text_medium_font_weight);
  }

  .ant-form-item-label > label {
    width: 100%;

    line-height: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
}
