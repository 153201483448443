.root {
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto 1fr;

  justify-content: center;

  &::before,
  &::after {
    content: "";
  }
}

.w360 {
  grid-template-columns: minmax(0, 360px);

  @media screen and (max-width: 767px) {
    grid-template-columns: minmax(0, 767px);
    grid-template-rows: 0 auto 1fr;
  }
}

.w672 {
  grid-template-columns: minmax(0, 672px);

  @media screen and (max-width: 767px) {
    grid-template-columns: minmax(0, 767px);
    grid-template-rows: 0 auto 1fr;
  }
}

.content {
  padding: 40px 0;
}
