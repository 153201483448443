.ConfirmationButtonsFooter {
  .BtnSuccess {
    color: #ffffff;
    background-color: #5cb85c;
    border-color: #4cae4c;

    &:hover,
    &:focus,
    &.focus,
    &:active {
      color: #ffffff;
      background-color: #449d44;
      border-color: #398439;
    }
  }

  .BtnBlack {
    color: #ffffff;
    background-color: #5e5e5e;
    border-color: #454545;

    &:hover,
    &:focus,
    &.focus,
    &:active {
      color: #ffffff;
      background-color: #454545;
      border-color: #262626;
    }
  }
}
