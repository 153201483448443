@mixin font() {
  font-size: var(--input_text_font_size);
  font-weight: var(--input_text_font_weight);
  line-height: var(--input_text_line_height);
}

@mixin borders() {
  height: var(--input_height);
  border-width: var(--input_border_width);
  border-radius: var(--input_border_radius);
  border-style: solid;
  outline-width: var(--input_outline_weight);
  outline-style: solid;
  padding: var(--input_padding);
}

@mixin colors($type) {
  background: var(--input_bg_#{$type});
  color: var(--input_text_color_#{$type});
  border-color: var(--input_border_color_#{$type});
  outline-color: var(--input_outline_color_#{$type});
}

@mixin inner_input_colors($type) {
  input {
    color: var(--input_text_color_#{$type});
    background: var(--input_bg_#{$type});
    box-shadow: none;
    border: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    color: var(--input_text_color_#{$type});
    background: var(--input_bg_#{$type});
    box-shadow: inset 0 0 0 var(--input_height) var(--input_box_shadow_#{$type}) !important;
    border: 0;
    -webkit-text-fill-color: var(--input_text_color_#{$type});
  }

  input::placeholder,
  input::-webkit-input-placeholder {
    color: var(--input_placeholder_color_#{$type});
  }
}
