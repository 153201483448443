.root {
    --text_color: #6d7c92;
    --border_color: #f2f3f5;
    --background_checkbox: #f2f3f5;
    --size_checkbox: 20px;

    align-items: center;

    :global{
        .ant-checkbox {
            top: 0;
        }

        .ant-checkbox .ant-checkbox-inner {
            width: var(--size_checkbox);
            height: var(--size_checkbox);
            border-radius: 2px;
            background-color: var(--background_checkbox) !important;
            border-color: var(--border_color) !important;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            --background_checkbox: #3385ff;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            content: url('./check.svg');
            transform: scale(1) translateY(-8px);
            border: none;
        }

        .ant-checkbox + span {
            padding-left: 10px;
            color: var(--text_color);
            font-size: var(--text_default_font_size);
            line-height: var(--text_default_line_height);
        }
    }

    &::after {
        height: var(--size_checkbox);
    }
}

.root:hover {
    --text_color: #21252c;
    --border_color: #7b899e;

    :global {
        .ant-checkbox-checked .ant-checkbox-inner {
            --background_checkbox: #0a54c2;
        }
    }
}

.root:global(.ant-checkbox-wrapper-disabled) {
    --text_color: #c4cad4;
    --border_color: #ffffff00;

    :global {
        .ant-checkbox-checked .ant-checkbox-inner {
            --background_checkbox: #f2f3f5;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
            border-color: #c4cad4 !important;
        }
    }
}
