@import "./Input.mixins.scss";

.root {
  &:global(.ant-input),
  :global(.ant-input) {
    @include font();

    &:-webkit-autofill,
    &:-webkit-autofill:first-line,
    &::placeholder,
    &::-webkit-input-placeholder {
      @include font();
    }
  }
}

.withoutPrefixSuffix:global(.ant-input) {
  @include borders();
  @include colors("default");

  &:hover {
    @include colors("hover");
  }

  &:focus,
  &:focus:hover {
    @include colors("active");
  }

  &:disabled,
  &:disabled:hover {
    @include colors("disabled");

    opacity: var(--input_disabled_opacity);
  }

  // TODO: добавить состояния для error
}

.withPrefixSuffix {
  :global(.ant-input-prefix) {
    margin-right: var(--input_prefix_margin_left);
  }

  &:global(.ant-input-affix-wrapper) {
    @include borders();
    @include colors("default");
    @include inner_input_colors("default");

    &:hover {
      @include colors("hover");
    }
  }

  &:global(.ant-input-affix-wrapper-focused),
  &:global(.ant-input-affix-wrapper-focused:hover) {
    @include colors("active");
    @include inner_input_colors("active");
  }

  &:global(.ant-input-affix-wrapper-disabled),
  &:global(.ant-input-affix-wrapper-disabled:hover) {
    @include colors("disabled");
    @include inner_input_colors("disabled");

    opacity: var(--input_disabled_opacity);
  }

  &:global(.ant-input-affix-wrapper-status-error) {
    background-color: var(--input_bg_default) !important;
  }

  // TODO: добавить состояния для error
}
