.root {
  --button_bg_default: #363E49;
  --button_bg_disabled: #e9ebed;
  --button_bg_hover: #414A58;
  --button_color_default: #ffffff;
  --button_color_disabled: #a7b1be;

  background-color: var(--button_bg_default);
  color: var(--button_color_default);
  border: none;
  text-transform: none;
  border-radius: 4px;
  font-weight: var(--text_bold_font_weight);
  line-height: var(--text_default_line_height);
  font-size: var(--text_large_font_size);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--button_bg_hover);
    color: var(--button_color_default);
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    background-color: var(--button_bg_disabled);
    color: var(--button_color_disabled);
  }
}

.accent {
  --button_bg_default: #b91928;
  --button_bg_hover: #D22D2D;
}

.secondary {
  --button_bg_default: #F4F5F6;
  --button_bg_hover: #EDEFF1;
  --button_color_default: var(--text_color_default);
}
