.root {
  position: relative;
}

.loader {
  color: var(--text_default);
  background-color: #ffffff55;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--card_radius);
}

.content {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.loading {
  opacity: 0.5;
}

.spin {
  display: inline-block;
  margin-right: var(--card_radius);
  -webkit-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
