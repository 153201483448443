.root {
  --bg_error_color_default: #fff0f0;
  --bg_warning_color_default: #dfa906;
  --bg_success_color_default: #ecf9f2;
  --margin_default: 16px;

  display: grid;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 var(--margin_default);
  padding: 16px;
  border-radius: 4px;
  font-size: var(--text_default_font_size);
  font-weight: 500;

  & > *:first-child {
    word-break: break-word;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.error {
    background-color: var(--bg_error_color_default);
    color: var(--text_color_default);
  }
  
  &.success {
    background-color: var(--bg_success_color_default);
    color: var(--text_color_default);
  }
  
  &.warning {
    background-color: var(--bg_warning_color_default);
    color: var(--neutral_1);
  }

  &.withCloseIcon {
    grid-template-columns: auto 16px;
    grid-gap: 20px;
  }

  .close {
    cursor: pointer;
  }
}

.root :global {
  a {
    color: var(--link_color_default);
  }

  .alert-row {
    margin-top: var(--margin_default);
  }
}
