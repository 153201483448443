.root {
  color: var(--text_default);

  &:global(.ant-select-focused.ant-select:not(.ant-select-disabled)) :global .ant-select-selector,
  &:global(.ant-select:not(.ant-select-disabled)):hover :global .ant-select-selector,
  &:global(.ant-select-single:not(.ant-select-customize-input)) :global .ant-select-selector {
    background-color: var(--input_bg_default);
    height: var(--input_height);
    border: solid 1px var(--input_border_default);
    border-radius: var(--input_radius);
    font-size: var(--input_font_size);

    box-shadow: none;
    font-weight: 400;
    padding: 0;

    &:hover {
      border-color: var(--input_border_hover);
    }

    .ant-select-selection-search {
      left: 0;
      right: 0;
      height: 100%;
    }

    .ant-select-selection-search-input {
      height: 100%;
      padding-left: 8px;
    }

    .ant-select-selection-placeholder {
      color: var(--input_placeholder_default);
      padding-left: 8px;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item {
      padding-left: 8px;
      display: flex;
      align-items: center;

      > div {
        flex-grow: 1;
      }

      mark {
        color: inherit;
        background: inherit;
      }
    }
  }

  &:global(.ant-select-open.ant-select-single:not(.ant-select-customize-input)) :global,
  &:global(.ant-select-open.ant-select-single:not(.ant-select-customize-input)):hover :global,
  &:global(.ant-select-focused.ant-select-single:not(.ant-select-customize-input)) :global {
    .ant-select-selector {
      border-color: var(--input_border_focused);
    }
  }
}

.dropdown {
  color: var(--text_default);
  background: var(--input_bg_default);
  border: solid 1px var(--input_border_focused);
  font-size: var(--select_dropdown_font_size);

  overflow: auto;
  box-shadow: none;

  &:global(.ant-select-dropdown-placement-topLeft) {
    border-radius: var(--input_radius) var(--input_radius) 0 0;
    border-bottom-color: var(--input_border_default);
  }

  &:global(.ant-select-dropdown-placement-bottomLeft) {
    border-radius: 0 0 var(--input_radius) var(--input_radius);
    border-top-color: var(--input_border_default);
  }
}

.dropdown :global {
  .ant-select-item-empty {
    min-height: unset;
  }

  mark {
    font-weight: bold;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
  }

  .status-bar {
    font-size: var(--input_label_font_size);
    padding: 0 8px 10px;

    .error-message {
      color: var(--text_error);
    }
  }

  .ant-select-item {
    padding-left: 8px;
    color: var(--text_default);
    font-size: var(--input_font_size);
    line-height: 24px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    padding-left: 8px;
    font-weight: normal;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    padding-left: 8px;
  }
}
