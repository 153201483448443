.root {
  color: var(--link_color_default);
  font-size: var(--text_small_font_size);
  line-height: var(--text_small_line_height);

  a,
  a:hover,
  a:active,
  a:visited {
    font-size: var(--text_small_font_size);
    color: var(--link_color_default);
  }
}

.dot {
  background-color: var(--link_color_default);
  border-radius: 50%;
  width: 4px;
  height: 4px;
}
