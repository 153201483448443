.root {
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 0 6px;

  &:hover {
    border: 1px solid #40a9ff;
    border-radius: 4px;
  }

  &:active {
    border: 1px solid #0079c8;
    border-radius: 4px;
  }
}

.btn {
  border-radius: 0;
  border: 0;
  color: #0079c8;
  background-color: #ffffff;
  cursor: pointer;
  padding: 0;
}

.title {
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: var(--text_error) !important;
}
