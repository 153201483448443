.root {
  color: var(--input_label_default);
  border-bottom: 1px solid #dfe1e7;

  a {
    color: var(--input_label_default);

    &:focus {
      font-weight: bold;
    }
  }
}

.link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.active {
    color: #0079c8;
    border-bottom: 3px solid #0079c8;
    cursor: default;
    position: relative;
    top: 1px;
    font-weight: bold;
  }
}
