.root {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-right: 7px;
}

.name,
.login {
  overflow: hidden;
  text-overflow: ellipsis;
}

.login::before {
  content: "@ ";
  white-space: nowrap;
  color: var(--text_default);
}
